import { useRouter } from 'next/router';

/* Language Helper */
const getLanguage = (lang: string) => {
  type langsObject = {
    [wors: string]: string;
  };

  const langs: langsObject = {
    ru: 'Русский',
    en: 'Английский',
    ua: 'Украинский',
    et: 'Эстонский',
    de: 'Немецкий',
    fr: 'Французский',
    lv: 'Латвия',
    lt: 'Литва',
    uk: 'Украинский',
    kk: 'Казахский',
    it: 'Итальянский',
    fi: 'Финский',
    cs: 'Чешский',
    unknown: 'Неизвестно',
  };
  return langs[lang.toLowerCase() as keyof langsObject] ?? lang;
};

/* Author fullname */
const getAuthorFullName = (authorFirstName: string, authorMiddleName: string, authorLastName: string): string => {
  let authorName = '';
  if (!!authorFirstName && authorFirstName.length > 0) {
    authorName = authorName.concat(authorFirstName);
  }
  if (!!authorMiddleName && authorMiddleName.length > 0) {
    authorName = authorName.concat(` ${authorMiddleName}`);
  }
  if (!!authorLastName && authorLastName.length > 0) {
    authorName = authorName.concat(` ${authorLastName}`);
  }
  if (authorName.length === 0) {
    return 'Aвтор не определен';
  }
  return authorName;
};

/*Get page numbers for static paths */
const getPagesNumbers = (pages: number) => {
  const pathNumbers = [];

  for (let i = 1; i <= pages; i++) {
    pathNumbers.push({ pageNumber: `${i}` });
  }
  return {
    pathNumbers,
  };
};

const getTruncatedTxt = (txt: string, size: number) => {
  if (txt === null) {
    return '';
  }
  return txt.length > size ? `${txt.slice(0, size)}…` : txt;
};

const getNoun = (number: number, one: string, two: string, five: string): string => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

const getBookNoun = (number: number) => {
  return getNoun(number, 'книга', 'книги', 'книг');
};

const getPodcastNoun = (number: number) => {
  return getNoun(number, 'подкаст', 'подкаста', 'подкастов');
};

const getPagesNumbersPaths = (pageLength: number) => {
  return Array.from({ length: pageLength }, (_, index) => ({
    params: {
      pageNumber: (index + 1).toString(),
    },
  }));
};

const getCurrentYear = () => {
  let today = new Date();
  return today.getFullYear();
};

const getCurrentMonth = () => {
  const month = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

  const d = new Date();
  return month[d.getMonth()];
};

const getNumWord = (value: number | undefined, words: string[]) => {
  if (value !== undefined) {
    value = Math.abs(value) % 100;
    const num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num == 1) return words[0];
    return words[2];
  }
};

const arrayRange = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => String(start + index * step));

const getFirstLetterCaps = (str: string) => {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
};

const getPlainSearchWords = (words: string) => {
  return words.includes('+') ? words.split('+').join(' ') : words;
};

const getShortTimeString = (str: string) => {
  if (str === null) {
    return;
  }
  if (str.includes('минут')) {
    str = str.replace(/минута|минуты|минут/gi, 'мин.');
  }
  if (str.includes('секунд')) {
    str = str.replace(/секунда|секунды|секунд/gi, 'cек.');
  }
  return str;
};

const hideImgOnError = (e: React.SyntheticEvent): void => {
  (e.target as HTMLImageElement).style.visibility = 'hidden';
};

const getCurrentPageName = (currentPage: number) => `${currentPage > 1 ? ` - Cтраница ${currentPage}` : ``}`;

export {
  getLanguage,
  getAuthorFullName,
  getPagesNumbers,
  getTruncatedTxt,
  getNoun,
  arrayRange,
  getPagesNumbersPaths,
  getCurrentYear,
  getCurrentMonth,
  getNumWord,
  getFirstLetterCaps,
  getPlainSearchWords,
  getShortTimeString,
  getBookNoun,
  getPodcastNoun,
  hideImgOnError,
  getCurrentPageName,
};
